






















import { Component, Ref, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import ModalAdminAdd from '@/components/organisms/ModalAdminAdd.vue'
import ModalUserNameEdit from '@/components/organisms/ModalUserNameEdit.vue'
import ModalConfirmGdlsCode from '@/components/organisms/ModalConfirmGdlsCode.vue'

@Component({
  components: {
    ButtonBase,
    TableBase,
    ModalAdminAdd,
    ModalUserNameEdit,
    ModalConfirmGdlsCode,
  },
})
export default class CustomerList extends Vue {
  private academyCode = ''

  private academyName = ''

  private currentPage = 1

  private count = 0

  private customerListTableBasePerPage = 30

  private customerListTableBaseItems: object[] = []

  private customerListTableBaseFields: object[] = [
    { key: 'adminName', label: '管理者氏名', thStyle: 'width: 400px' },
    { key: 'gdlsCode', label: 'GDLSコード' },
    { key: 'reissue', label: '再発行' },
    { key: 'gakkenId', label: 'G-ID' },
    { key: 'delete', label: '' },
  ]

  private customerListTableBaseButtons: string[] = ['gdlsCode', 'reissue', 'delete']

  private customerListTableBaseLinks: string[] = ['adminName']

  @Ref()
  modalEdit!: ModalUserNameEdit

  @Ref()
  modalAdd!: ModalAdminAdd

  @Ref()
  modalGdls!: ModalConfirmGdlsCode

  private showAdminAdd(): void {
    this.modalAdd.setId(parseInt(this.$route.params.id))
  }

  private showAdminNameEdit(user: { id: number; nickname: string }): void {
    this.modalEdit.showWithUser(user.id, user.nickname)
  }

  private showGdlsCode(user: { nickname: string; gdlsCode: string }): void {
    this.modalGdls.setManager(user.nickname, user.gdlsCode)
  }

  /**
   * 塾管理者一覧を取得する
   */
  private async loadAdmins() {
    const params = [
      `limit=${this.customerListTableBasePerPage}`,
      `offset=${this.customerListTableBasePerPage * (this.currentPage - 1)}`,
    ]
    await Vue.prototype.$http.httpWithToken
      .get(`/academies/${this.$route.params.id}/users?${params.join('&')}`)
      .then((res: any) => {
        this.academyCode = res.data.academy.code
        this.academyName = res.data.academy.name
        this.customerListTableBaseItems = res.data.users.map(
          (user: { id: number; nickname: string; gdlsCode: string; isGidRegistered: boolean }) => {
            return {
              id: user.id,
              adminName: {
                onclick: this.showAdminNameEdit,
                variable: { id: user.id, nickname: user.nickname },
                name: user.nickname,
              },
              gdlsCode: [
                {
                  onclick: this.showGdlsCode,
                  variable: { nickname: user.nickname, gdlsCode: user.gdlsCode },
                  name: '確認',
                },
              ],
              reissue: [{ onclick: this.reissueGdls, variable: user.id, name: '再発行' }],
              gakkenId: user.isGidRegistered ? '済' : '未',
              delete: [{ onclick: this.destroyAdmin, variable: user.id, name: '削除' }],
            }
          }
        )
        this.count = res.data.count
      })
  }

  /**
   * GDLSコードを再発行する
   */
  private async reissueGdls(id: number) {
    if (!confirm('再発行しますか？')) return

    await Vue.prototype.$http.httpWithToken
      .post(`/users/${id}/gdlsCode`)
      .then(() => {
        this.loadAdmins()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }

  /**
   * 管理者を削除する
   */
  private async destroyAdmin(id: number) {
    if (!confirm('削除しますか？')) return

    await Vue.prototype.$http.httpWithToken
      .delete(`/users/${id}`)
      .then(() => {
        // 管理者を削除したことで現在のページの塾がなくなる場合は1ページ前を表示する
        if (this.customerListTableBaseItems.length === 1 && this.currentPage > 1)
          this.currentPage = this.currentPage - 1
        this.loadAdmins()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }

  /**
   * ページネーション
   */
  private paginate(page: number): void {
    this.currentPage = page
    this.loadAdmins()
  }

  private async mounted() {
    this.loadAdmins()
  }
}
