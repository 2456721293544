



















import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    ModalBase,
    ButtonBase,
  },
})
export default class ModalAdminAdd extends Mixins(ModalBaseMethod) {
  private id = 0

  private classroomDatas: { nickname: '' }[] = [{ nickname: '' }]

  private isProcessing = false

  @Prop()
  title?: string

  @Prop()
  subjectTitle?: string

  /**
   * バリデーションエラー
   */
  private get nameErrors(): { index: number; error: string }[] {
    const errors = [] as { index: number; error: string }[]
    for (let i = 0; i < this.classroomDatas.length; i++) {
      if (this.classroomDatas[i].nickname !== '') {
        if (this.classroomDatas[i].nickname.length > 20) {
          errors.push({ index: i, error: `${this.subjectTitle}は20文字までです` })
        }
      }
    }
    return errors
  }

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    const permitted =
      this.classroomDatas.length > 0 &&
      this.classroomDatas.filter((classroom) => classroom.nickname === '').length === 0 &&
      this.nameErrors.length === 0

    return permitted ? 'green' : 'pointer-events-none'
  }

  public async setId(id: number) {
    this.id = id
    this.isProcessing = false
    this.show()
  }

  private addRow(): void {
    this.classroomDatas.push({ nickname: '' })
  }

  private removeRow(index: number): void {
    this.classroomDatas.splice(index, 1)
  }

  /**
   * 塾管理者を追加するメソッド
   */
  private async createAdmins() {
    if (this.isProcessing === false) {
      this.isProcessing = true
      await Vue.prototype.$http.httpWithToken
        .post('/users/academies', { academyId: this.id, users: this.classroomDatas })
        .then(() => {
          this.classroomDatas = [{ nickname: '' }]
          this.$emit('hide-model')
          this.hide()
        })
    }
  }
}
